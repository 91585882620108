import { useState } from "react";

function HomePage() {
  return (
    <div class="app">
      <h1>Meal Hacks</h1>
    </div>
  );
}

export default HomePage;